import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import Button from "../components/button"

const ContactForm = () => {
  const [status, setStatus] = useState("");

  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  };

  return (
    <div
      style={{
        margin: "20px auto",
        display: "flex",
        justifyContent: "center",
        padding: "15px",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <h3>Let's get in touch!</h3>
      <form
        onSubmit={submitForm}
        action="https://formspree.io/xwkwqklv"
        method="POST"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <TextField
          variant="outlined"
          label="E-Mail"
          type="email"
          name="email"
        />
        <TextField
          variant="outlined"
          label="Mesaj"
          type="text"
          name="message"
          multiline
          style={{ marginTop: "10px" }}
        />
        {status === "SUCCESS" ? (
          <p>
            <span style={{ color: "#261132" }}>Thanks!</span> I got your email and will return to you ASAP!
          </p>
        ) : (
            <Button  marginTop='10px'>
              Send
            </Button>
          )}
        {status === "ERROR" && (
          <p>
            <span style={{ color: "red" }}>Ooops!</span> There was an issue. You can try sending again or use other platforms to contact me!
          </p>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
