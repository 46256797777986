import React from "react"
import App from '../components/App'
import SEO from '../components/seo'
class IndexPage extends React.Component {
  render() {

    return (
      <div>
        <App />
        <SEO
          title="Home"
          keywords={[`elifcerenkaya`, `neuroscience`, `marketing`, `elif`, 'ceren', 'kaya', 'Bilkent']}
        />
      </div>
    )
  }
}

export default IndexPage
