import React from "react"

const About = () => {
    return (
        <div style={{textAlign: "center"}}>
            <h3 style={{textDecoration: "underline"}}>About</h3>
            <p>
                I graduated from Bilkent Psychology Department in June 2020. I am interested in organizational
                psychology, digital media and marketing. I did internships in clinical psychology and counseling in
                Turkey, Poland and Austria and worked as a research assistant.
                I learned a lot of new things and experienced different works in all of them. After what I learned, I
                took elective courses in different fields in my senior year and continued lessons on subjects that I was
                interested in from online platforms such as Coursera. In this direction, I started my job search by
                directing my career goals towards consumer behavior and marketing.
            </p>
        </div>
    )
}

export default About
