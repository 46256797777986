import React, { useEffect } from "react";
import Header from "./Header";
import Container from "@material-ui/core/Container";
import BlogList from "./BlogList";
import About from "./About";
import ContactForm from "./ContactForm";
import ReactGA from "react-ga";



function initGA() {
  ReactGA.initialize("UA-108177941-3");
  ReactGA.pageview("home");
}

const App = () => {
  useEffect(() => {
    initGA();
  }, []);
  return (
    <div>
      <Container style={{ marginTop: "80px" }} maxWidth="lg">
        <span style={{fontFamily: 'Lobster', position: 'absolute', top: '10px', left: '20px'}}>Elif Ceren Kaya</span>
        <Header />
        <About />
        <BlogList />
        <ContactForm />
      </Container>
    </div>
  );
};

export default App;
