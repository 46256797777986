import React from "react"

/* import { rhythm } from "../utils/typography"
import Tags from "./Tags"
*/
import { StaticQuery, graphql } from "gatsby"
const ProjectList = () => {
  return (
    <StaticQuery
      query={pageQuery}
      render={() => {
        // const posts = data.allMdx.edges
        return (
          <div
            id="projects"
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
            key="projects"
          >
            <h3 style={{ textDecoration: "underline" }}>My blogs</h3>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-evenly",
              }}
            >
              {/* {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                const tags = node.frontmatter.tags
                return (
                  <div
                    key={node.fields.slug}
                    style={{
                      margin: "10px",
                      flexBasis: "0 0 30%",
                      maxWidth: 345,
                      backgroundColor: "#db9068",
                      border: "1px solid #10375c",
                      borderRadius: "10px",
                      fontSize: '0.7em',
                      padding: '5px'
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: rhythm(1 / 2),
                        marginTop: rhythm(1 / 2),
                      }}
                    >
                      <Link
                        style={{ boxShadow: `none` }}
                        to={`/blog${node.fields.slug}`}
                      >
                        {title}
                      </Link>
                    </h3>
                    <small>{node.frontmatter.date}</small>
                    {tags && tags.length > 0 ? ` - ` : ``}
                    <Tags children={tags} />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                    <Link
                      style={{ boxShadow: `none` }}
                      to={`/blog${node.fields.slug}`}
                    >
                      <b>Okumak için tıklayın!</b>
                    </Link>
                  </div>
                )
              })} */}
              <p>
                I haven't shared anything. Check back soon!
              </p>
            </div>
            {/* <span style={{ fontSize: "0.9rem" }}>
                Daha fazlası için blogumu ziyaret <span><a href={'/blog'}>edin.</a></span>
            </span> */}
          </div>
        )
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`

export default ProjectList
