import React from "react"
import Container from "@material-ui/core/Container"
import { IoLogoLinkedin, IoLogoInstagram, IoMdDocument } from "react-icons/io"
import { StaticQuery, graphql } from "gatsby"
import Photo from "../../content/assets/photo.jpeg"
const Info = () => {
  return (
    <StaticQuery
      query={headerQuery}
      render={data => {
        console.log(data)
        const { social } = data.site.siteMetadata
        return (
          <Container className="info" maxWidth="lg">
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ maxWidth: "200px", borderRadius: "7px" }}
                src={Photo}
                alt="Elif Ceren Kaya"
              />
              <p style={{ padding: "5px" }}>
                Hi, I'm <span style={{ fontWeight: "bold" }}>Ceren</span>,{" "} I work as a {" "}
                <span style={{ backgroundColor: "#EF5D60" }}>Marketing Research Specialist</span>{" "} at Crs Soft
                and{" "} I'm pursuing a {" "}
                <span style={{ backgroundColor: "#EF5D60" }}>
                  Neuromarketing
                </span>{" "} Master's degree. {" "}
                  In my free time, I enjoy cooking and sharing it on my <a href={"https://www.instagram.com/bonveggietite/"}>
                  instagram
                </a> account.
              </p>
            </div>
            <p style={{ marginTop: "15px" }}>
              <a href={`https://linkedin.com/in/${social.linkedin}`}>
                <IoLogoLinkedin />
              </a>
              <a href={`https://instagram.com/${social.instagram}`}>
                <IoLogoInstagram />
              </a>
              <p>
                <a href="/resume.pdf">
                  {" "}
                  <IoMdDocument />
                </a>
              </p>
              CV
            </p>
          </Container>
        )
      }}
    />
  )
}

const headerQuery = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        author
        social {
          twitter
          instagram
          linkedin
        }
      }
    }
  }
`

export default Info
